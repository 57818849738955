import React from "react";
import TitleImageComponent from "../../components/image/TitleImageComponent";
import {Accordion, Breadcrumb, Button, Grid, Icon, Image} from "semantic-ui-react";
import browserHistory from "../../helper/browserHistory";
import EditSimpleValueComponent from "../../components/moap-components/EditSimpleValueComponent";
import EditHTMLComponent from "../../components/moap-components/EditHTMLComponent";
import {inject} from "mobx-react";
import config from "../../config/main.config";
import EditImageComponent from "../../components/moap-components/EditImageComponent";
import {getAllPartner} from "../partner/PartnerPage";
import Card from "semantic-ui-react/dist/commonjs/views/Card";
import history from "../../helper/browserHistory";

const linkExp = new RegExp(/^(https?:\/\/)/)
const PAGE_LINK = "Unternehmen Engagement";

const SLIDER_ITEMS = [{
    url: "https://www.kreissparkasse-ravensburg.de/de/home.html",
    src: `${config.PUBLIC_URL}ksk-logo.jpg`,
    text: "Kreissparkasse Ravensburg"
}, {
    url: "http://www.agenturinhalt.de/", src: `${config.PUBLIC_URL}agenturInhalt.png`, text: "agenturInhalt."
}, {
    url: "https://aaa-soft.net/",
    src: `${config.PUBLIC_URL}triple-a-soft.svg`,
    text: "Triple-A Soft UG (haftungsbeschränkt)"
}, {
    url: "https://www.chg-meridian.com/de/",
    src: `https://upload.wikimedia.org/wikipedia/commons/thumb/4/48/Logo_CHG-MERIDIAN.svg/500px-Logo_CHG-MERIDIAN.svg.png`,
    text: "CHG-MERIDIAN AG"
}, {
    url: "https://www.thuega.de/",
    src: `${config.PUBLIC_URL}images/thüga-schriftzug-mit-claim-cmyk.jpg`,
    text: "Thüga Energie GmbH"
}, {
    url: "https://www.adoma.de", src: `${config.PUBLIC_URL}images/adoma_logo_cmyk.svg`, text: "ADOMA GmbH"
}, {
    url: "https://www.wohlwender.de/",
    src: `${config.PUBLIC_URL}images/LOGO-JW1.webp`,
    text: "Wohlwender Baugeschäft GmbH"
}]

@inject("stores")
class CompanyEngagementPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            textStore: props.stores.textStore.texts,
            isLoading: true,
            showFirstAccordion: false,
            showSecondAccordion: false,
            sliderItems: SLIDER_ITEMS,
            shownPartners: [],
            allPartners: []

        }
    }

    componentDidMount() {
        this.props.stores.trackingStore.addVisit(PAGE_LINK);
        window.scrollTo(0, 0);
        getAllPartner().then(allPartner => {
            this.setState({...this.state, allPartner: allPartner, shownPartners: allPartner});
        })
    }

    showPartner = () => {
        const partners = this.state.shownPartners.map((partner, index) => {
            if (partner.type === 1) {
                return (
                    <div style={{margin: '1rem'}}>
                        <Card className={'partner-card-wrapper'}
                              onClick={_ => linkExp.test(partner.link) ? window.open(partner.link, 'blank_') : history.push(partner.link)}>
                            <img src={config.BASE_IMAGE_URL + partner.image} alt={partner.image}
                                 style={{padding: '.2rem', objectFit: 'contain', height: '50%'}}/>
                            <Card.Content>
                                <Card.Header>{partner.name}</Card.Header>
                                <Card.Description className={'card-overflow'}>
                                    {partner.description}
                                </Card.Description>
                            </Card.Content>
                            <Card.Content extra>
                                <Button circular fluid className={"call-to-action-button"}
                                        onClick={() => {
                                            // setFundingsToShow(fundingsToShow + MORE_FUNDINGS_AMOUNT);
                                        }}>
                                    MEHR ERFAHREN
                                </Button>
                            </Card.Content>
                        </Card>
                    </div>)
            }
        })
        return (
            <Card.Group centered>
                {partners}
            </Card.Group>
        )
    }


    render() {


        const Seperator = "https://www.buergerstiftung-kreis-rv.de/images/seperator.png";

        return (<Grid>
            <TitleImageComponent
                textStore={this.state.textStore}
                namespace={'engage'}
                imageLinkId={"company-engagement-title-image-id"}
                headerId={"company-engagement-title-header-id"}
                subheaderId={"company-engagement-subtitle-header-id"}
                small
            />
            <Grid.Row>
                <Grid.Column width={2} only={'computer'}/>
                <Grid.Column computer={14} tablet={16} mobile={16}>
                    <Breadcrumb className={"breadcrumb-container"}>
                        <Breadcrumb.Section className={"inactive-breadcrumb"} onClick={() => {
                            browserHistory.push("/");
                            this.setState({});
                        }}>
                            HOME</Breadcrumb.Section>
                        <Breadcrumb.Divider icon='right chevron'/>
                        <Breadcrumb.Section
                            className={"active-breadcrumb"}>{"Engagement für Unternehmen".toUpperCase()}</Breadcrumb.Section>
                    </Breadcrumb>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row centered className={"page-row"}>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                    <div style={{marginTop: '3rem'}}>
                        <h2>
                            <EditSimpleValueComponent textStore={this.state.textStore}
                                                      id={"company-engagement-title-sector-1-id"}
                                                      namespace={'engage'}/>
                        </h2>
                        <p className={'text-block'}>
                            <EditSimpleValueComponent textStore={this.state.textStore}
                                                      id={'company-engagement-subtitle-sector-1-id'}
                                                      namespace={'engage'}/>
                        </p>
                    </div>
                </Grid.Column>
                <Grid.Column className={'four wide computer sixteen wide mobile sixteen wide tablet column'}
                             style={{padding: '3rem'}}>
                    <EditImageComponent id={'company-engagement--image-1-id'}/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row centered>
                <Grid.Column width={12}>
                    <Accordion styled style={{width: '100%', marginBottom: '2rem', textAlign: 'left'}}>
                        <Accordion.Title active={this.state.showFirstAccordion}
                                         style={{display: 'inline-flex'}}
                                         onClick={_ => {
                                             this.setState({
                                                 ...this.state, showFirstAccordion: !this.state.showFirstAccordion
                                             })
                                         }}>
                            <Icon name={this.state.showFirstAccordion ? 'chevron down' : 'chevron right'}
                                  style={{margin: 'auto 0'}}
                                  size={"large"}/>
                            <h2 style={{margin: 'auto 0', overflowWrap: 'anywhere'}}><EditSimpleValueComponent
                                textStore={this.state.textStore}
                                id={'company-engagement-accordion-title-1'}
                                namespace={'engage'}/></h2>
                        </Accordion.Title>
                        <Accordion.Content active={this.state.showFirstAccordion}>
                            <Grid.Column>
                                <p className={"text-block"} style={{textAlign: 'left'}}>
                                    <EditHTMLComponent textStore={this.state.textStore}
                                                       id={"company-engagement-accordion-1-text"}
                                                       namespace={'engage'}/>
                                </p>
                            </Grid.Column>
                        </Accordion.Content>
                    </Accordion>
                    <Accordion styled style={{width: '100%', float: "left"}}>
                        <Accordion.Title active={this.state.showSecondAccordion}
                                         style={{display: 'inline-flex'}}
                                         onClick={_ => {
                                             this.setState({
                                                 ...this.state, showSecondAccordion: !this.state.showSecondAccordion
                                             })
                                         }}>
                            <Icon name={this.state.showSecondAccordion ? 'chevron down' : 'chevron right'}
                                  style={{margin: 'auto 0'}}
                                  size={"large"}/>
                            <h2 style={{margin: 'auto 0', overflowWrap: 'anywhere'}}><EditSimpleValueComponent
                                textStore={this.state.textStore}
                                id={'company-engagement-accordion-title-2'}
                                namespace={'engage'}/></h2>
                        </Accordion.Title>
                        <Accordion.Content active={this.state.showSecondAccordion}>
                            <Grid.Column width={12} style={{overflowX: 'auto'}}>
                                <table style={{margin: 'auto', borderSpacing: "8rem 2rem"}}>
                                    <tr>
                                        <td className={'company-engagement-table-cell'}><EditSimpleValueComponent
                                            textStore={this.state.textStore}
                                            id={'company-engagement-tabel-cell-1'}
                                            namespace={'engage'}/></td>
                                        <td className={'company-engagement-table-cell'}><EditSimpleValueComponent
                                            textStore={this.state.textStore}
                                            id={'company-engagement-tabel-cell-2'}
                                            namespace={'engage'}/></td>
                                        <td className={'company-engagement-table-cell'}><EditSimpleValueComponent
                                            textStore={this.state.textStore}
                                            id={'company-engagement-tabel-cell-3'}
                                            namespace={'engage'}/></td>
                                    </tr>
                                    <tr>
                                        <td className={'company-engagement-table-cell'}><EditSimpleValueComponent
                                            textStore={this.state.textStore}
                                            id={'company-engagement-tabel-cell-4'}
                                            namespace={'engage'}/></td>
                                        <td className={'company-engagement-table-cell'}><EditSimpleValueComponent
                                            textStore={this.state.textStore}
                                            id={'company-engagement-tabel-cell-5'}
                                            namespace={'engage'}/></td>
                                        <td className={'company-engagement-table-cell'}><EditSimpleValueComponent
                                            textStore={this.state.textStore}
                                            id={'company-engagement-tabel-cell-6'}
                                            namespace={'engage'}/></td>
                                    </tr>
                                    <tr>
                                        <td className={'company-engagement-table-cell'}><EditSimpleValueComponent
                                            textStore={this.state.textStore}
                                            id={'company-engagement-tabel-cell-7'}
                                            namespace={'engage'}/></td>
                                        <td className={'company-engagement-table-cell'}><EditSimpleValueComponent
                                            textStore={this.state.textStore}
                                            id={'company-engagement-tabel-cell-8'}
                                            namespace={'engage'}/></td>
                                        <td className={'company-engagement-table-cell'}><EditSimpleValueComponent
                                            textStore={this.state.textStore}
                                            id={'company-engagement-tabel-cell-9'}
                                            namespace={'engage'}/></td>
                                    </tr>
                                </table>
                                <p className={"text-block"}>
                                    <EditHTMLComponent textStore={this.state.textStore}
                                                       id={"company-engagement-text-sector-3-id"}
                                                       namespace={'engage'}/>
                                </p>
                            </Grid.Column>

                        </Accordion.Content>
                    </Accordion>
                </Grid.Column>
            </Grid.Row>
        <Grid.Row centered>
            <Grid.Column mobile={16} tablet={16} computer={16} style={{padding: '5rem'}}>
                <Image centered
                       src={Seperator}
                       size={"tiny"}
                />
            </Grid.Column>
            <Grid.Column computer={8} tablet={16} mobile={16}>
                <h2><EditSimpleValueComponent textStore={this.state.textStore}
                                              id={"company-engagement-title-sector-5-id"}
                                              namespace={'engage'}/>
                </h2>
                <p className={'text-block'}>
                    <EditSimpleValueComponent textStore={this.state.textStore}
                                              id={'company-engagement-text-sector-5-id'}
                                              namespace={'engage'}/>
                </p>
                <Button circular className={"call-to-action-button"}
                        style={{width: '300px', margin: "1rem"}}
                        onClick={() => {
                            browserHistory.push("/contact");
                            this.setState({});
                        }}>
                    {"Kontakt".toUpperCase()}
                </Button>
            </Grid.Column>
            <Grid.Column computer={4} tablet={14} mobile={14}>
                <EditImageComponent id={'company-engagement-image-2-id'}/>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
            <Grid.Column computer={12} tablet={16} mobile={16}>
                <h2><EditSimpleValueComponent textStore={this.state.textStore}
                                              id={'company-engagement-partner-title'}
                                              namespace={'engage'}/></h2>
                {this.showPartner()}
            </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
            <Grid.Column computer={8} tablet={16} mobile={16}>
                <h2>
                    <EditSimpleValueComponent textStore={this.state.textStore}
                                              id={"company-engagement-title-sector-6-id"}
                                              namespace={'engage'}/>
                </h2>
                <p className={'text-block'}>
                    <EditHTMLComponent textStore={this.state.textStore}
                                       id={'company-engagement-text-sector-6-id'}
                                       namespace={'engage'}/>
                </p>
            </Grid.Column>
            <Grid.Column computer={4} tablet={16} mobile={16}>
                <EditImageComponent id={'company-engagement-image-3-id'}/>
            </Grid.Column>
        </Grid.Row>
    </Grid>)
        ;
    }
}

export default CompanyEngagementPage